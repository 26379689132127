import { Inject, Injectable, OnInit } from '@angular/core';
import { SHOPIFY_Y, SHOPIFY_S } from './cart-constants';
import { Attributes, buildUUID, getShopifyCookies } from './cookies-utils';
import { EnvironmentService } from '../environment.service';
import { DOCUMENT } from '@angular/common';
import { stringify } from 'querystring';

type Options = Omit<Attributes, 'expires'> & {
  expires?: Date | string | number;
};

@Injectable({
  providedIn: 'root',
})
export class ShopifyCookiesService implements OnInit {
  private longTermLength = 60 * 60 * 24 * 360 * 1; // ~1 year expiry
  private shortTermLength = 60 * 30; // 30 mins

  constructor(
    @Inject(DOCUMENT) private document: Document,

    private environmentService: EnvironmentService
  ) {}

  ngOnInit() {}

  stringify(name: string, value: string, options: Options = {}): string {
    let str = name + '=' + encodeURIComponent(value);

    if (options.expires) {
      str += '; Expires=' + new Date(options.expires).toUTCString();
    }

    if (options.maxage != null && options.maxage >= 0) {
      str += '; Max-Age=' + (options.maxage | 0);
    }

    if (options.domain) {
      str += '; Domain=' + options.domain;
    }

    if (options.path) {
      str += '; Path=' + options.path;
    }

    if (options.samesite) {
      str += '; SameSite=' + options.samesite;
    }

    if (options.secure || options.samesite === 'None') str += '; Secure';
    if (options.httponly) str += '; HttpOnly';

    return str;
  }

  useShopifyCookies(options?: UseShopifyCookiesOptions): void {
    if (!this.environmentService.isBrowser()) return;
    const {
      hasUserConsent = true,
      domain = this.environmentService.environment.siteUrl,
      checkoutDomain = this.environmentService.environment.shopifyUrl,
    } = options || {};
    const cookies = getShopifyCookies(this.document.cookie);

    let currentDomain = domain || window.document.location.host;

    if (checkoutDomain) {
      const checkoutDomainParts = checkoutDomain.split('.').reverse();
      const currentDomainParts = currentDomain.split('.').reverse();
      const sameDomainParts: string[] = [];
      checkoutDomainParts.forEach((part: string, index: number) => {
        if (part === currentDomainParts[index]) {
          sameDomainParts.push(part);
        }
      });

      currentDomain = sameDomainParts.reverse().join('.');
    }

    if (/^localhost/.test(currentDomain)) currentDomain = '';

    const domainWithLeadingDot = currentDomain
      ? /^\./.test(currentDomain)
        ? currentDomain
        : `.${currentDomain}`
      : '';
    if (hasUserConsent) {
      this.setCookie(
        SHOPIFY_Y,
        cookies[SHOPIFY_Y] || buildUUID(),
        this.longTermLength,
        domainWithLeadingDot
      );
      this.setCookie(
        SHOPIFY_S,
        cookies[SHOPIFY_S] || buildUUID(),
        this.shortTermLength,
        domainWithLeadingDot
      );
    } else {
      this.setCookie(SHOPIFY_Y, '', 0, domainWithLeadingDot);
      this.setCookie(SHOPIFY_S, '', 0, domainWithLeadingDot);
    }
  }

  private setCookie(
    name: string,
    value: string,
    maxage: number,
    domain: string
  ): void {
    document.cookie = this.stringify(name, value, {
      maxage,
      domain,
      samesite: 'Lax',
      path: '/',
    });
  }
}

export interface UseShopifyCookiesOptions {
  hasUserConsent?: boolean;
  domain?: string;
  checkoutDomain?: string;
}
