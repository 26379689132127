import { NgClass, NgIf } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { StoryblokLinkDirective } from 'src/app/directive/storyblok-link.directive';
import { EnvironmentService } from 'src/app/service/environment.service';

@Component({
  selector: 'app-email-form',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, NgClass, StoryblokLinkDirective],
  templateUrl: './email-form.component.html',
  styleUrl: './email-form.component.scss',
})
export class EmailFormComponent {
  @Input() theme: any;
  loading: boolean = false;
  success: boolean = false;
  error: boolean = false;
  contactForm: FormGroup;
  isFormValid: boolean = false;
  environment = this.environmentService.environment;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) {
    // Using FormBuilder to create the FormGroup.
    this.contactForm = this.fb.group({
      name: [''],
      email: ['', [Validators.required, Validators.email]],
    });
    this.contactForm.statusChanges.subscribe((status) => {
      this.isFormValid = status === 'VALID';
    });
  }

  submit() {
    const options = {
      headers: new HttpHeaders({
        revision: '2024-06-15',
      }),
      body: {
        data: {
          type: 'subscription',
          attributes: {
            custom_source: window.location.href,
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  first_name: this.contactForm.value.name.split(' ')[0],
                  last_name: this.contactForm.value.name.split(' ')[1],
                  email: this.contactForm.value.email,
                  properties: {},
                },
              },
            },
          },
          relationships: {
            list: {
              data: {
                type: 'list',
                id: this.environmentService.environment.klaviyoList,
              },
            },
          },
        },
      },
    };

    const url =
      'https://a.klaviyo.com/client/subscriptions/?company_id=' +
      this.environmentService.environment.klaviyoId;
    this.loading = true;
    this.http.post(url, options.body, { headers: options.headers }).subscribe(
      (data: any) => {
        this.success = true;
      },
      (err) => {
        this.success = false;
        this.error = err;
      },
      () => {
        this.loading = false;
        setTimeout(() => {
          this.contactForm.reset();
        }, 6000);
      }
    );
  }
}
