import { Injectable } from '@angular/core';
import { AddToCartItem, Cart, Product } from '../classes/utility';
import { EnvironmentService } from './environment.service';
declare global {
  interface Window {
    klaviyo: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class KlaviyoService {
  klaviyoScript: any;
  constructor(private enviornmentService: EnvironmentService
  ) { }

  async addToCart(variants: AddToCartItem[], cart: Cart) {
    for (let variant of variants) {
      let foundVariant = cart.lines.edges.find(
        (x) => x.node.merchandise.id == variant.variantId
      )?.node;
      if (!foundVariant) {
        continue;
      }
      await this.fireKlaviyoEvent('Added to Cart', {
        $value: foundVariant.merchandise.priceV2.amount,
        AddedItemProductName: foundVariant.merchandise.product.title,
        AddedItemProductID: foundVariant.merchandise.id,
        AddedItemSKU: foundVariant.merchandise.sku,
        AddedItemCategories: [],
        AddedItemImageURL: foundVariant.merchandise.image.transformedSrc,
        AddedItemURL: window.location.href,
        AddedItemPrice: foundVariant.merchandise.priceV2.amount,
        AddedItemQuantity: foundVariant.quantity,
        ItemNames: cart.lines.edges.map(
          (item: any) => item.node.merchandise.product.title
        ),
        CheckoutURL: cart.checkoutUrl,
        Items: cart.lines.edges.map((item: any) => {
          return {
            ProductID: item.node.merchandise.id.includes(
              'gid://shopify/ProductVariant/'
            )
              ? item.node.merchandise.id.split(
                'gid://shopify/ProductVariant/'
              )[1]
              : item.node.merchandise.id,
            SKU: item.node.merchandise.sku,
            ProductName: item.node.merchandise.product.title,
            Quantity: item.node.quantity,
            ItemPrice: item.node.merchandise.priceV2.amount,
            RowTotal:
              parseFloat(item.node.merchandise.priceV2.amount) *
              item.node.quantity,
            ProductURL: window.location.href,
            ImageURL: item.node.merchandise.image.transformedSrc,
            ProductCategories: [],
          };
        }),
      });
    }
  }

  async viewProduct(product: Product) {
    if (!this.enviornmentService.isBrowser()) return
    await this.fireKlaviyoEvent('Viewed Product', {
      ProductName: product.title,
      ProductID: product.variants[0].id,
      SKU: product.variants[0].sku,
      ...((product as any).categories && {
        Categories: (product as any).categories,
      }),
      ImageURL: product.image.src,
      URL: window.location.href,
      Brand: product.vendor,
      Price: product.min_price,
      ...(product.compare_at_min_price && {
        CompareAtPrice: product.compare_at_min_price,
      }),
    });
  }

  async fireKlaviyoEvent(
    eventType: 'Viewed Product' | 'Added to Cart',
    eventBody: any
  ) {
    try {
      window.klaviyo.push(['track', eventType, eventBody]);
    } catch (err) {
      console.log(err);
    }
  }
}
