import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { StoryBlokService } from '../service/storyblok.service';
import { ThemeService } from '../service/theme.service';

@Injectable({ providedIn: 'root' })
export class StoryblokAllBlogsResolver implements Resolve<any> {
  constructor(
    private storyBlokService: StoryBlokService,
    private router: Router,
    private themeService: ThemeService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const urlSegments = route.url.map((segment) => segment.path).join('/');
    const storyPath = urlSegments || 'home';

    const articlesPromise = this.storyBlokService
      .getStories({
        content_type: 'article',
        starts_with: 'blogs/articles',
        sort_by: 'created_at:desc',
      })
      .then((data) => {
        for (let entry of data.stories) {
          (entry as any).type = entry.full_slug.includes('article')
            ? 'Article'
            : 'Recipe';
        }
        return data.stories;
      });

    const recipesPromise = this.storyBlokService
      .getStories({
        content_type: 'article',
        starts_with: 'blogs/recipes',
        sort_by: 'created_at:desc',
      })
      .then((data) => {
        for (let entry of data.stories) {
          (entry as any).type = entry.full_slug.includes('article')
            ? 'Article'
            : 'Recipe';
        }
        return data.stories;
      });

    const storyPromise = this.storyBlokService
      .getStory(storyPath, { resolve_relations: ['all-blogs.featured'] })
      .then((result) => {
        result.story.content.featured[0].type =
          result.story.content.featured[0].full_slug.includes('article')
            ? 'Article'
            : 'Recipe';

        const title =
          (result?.story?.content as any)?.metatags?.title ||
          result.story.name ||
          'All Blogs';
        const description = (result?.story?.content as any)?.metatags
          ?.description;
        return {
          ...result,
          seo: { title, description },
        };
      })
      .catch((err) => {
        console.log(err);
        this.router.navigate([{ outlets: { error: ['404'] } }], {
          skipLocationChange: true,
        });
        return null;
      });

    const [story, articles, recipes] = await Promise.all([
      storyPromise,
      articlesPromise,
      recipesPromise,
    ]);

    return {
      story,
      articles,
      recipes,
    };
  }
}
