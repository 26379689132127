<div
  [@fade]
  [appStoryblok]="_editable"
  *ngFor="let blok of theme?.content?.ribbon"
>
  <ndc-dynamic
    *ngIf="components"
    [ndcDynamicComponent]="components[blok.component]"
    [ndcDynamicInputs]="blok"
  >
  </ndc-dynamic>
</div>
