<h5 class="mb-4">{{ theme?.content?.newsletter_motivator }}</h5>

<div *ngIf="!loading && success && !error">
  <h4>Thanks For Subscribing!</h4>
  <p>We'll send updates straight to your inbox.</p>
</div>
<form
  *ngIf="
    !loading &&
    !success &&
    !error &&
    (environment.klaviyoList || !environment.production)
  "
  [formGroup]="contactForm"
  [ngClass]="{ validated: contactForm.dirty && contactForm.touched }"
  (ngSubmit)="submit()"
>
  <div class="form-floating mb-3">
    <input
      placeholder="Name"
      class="form-control"
      id="name"
      type="text"
      formControlName="name"
    />
    <label for="name">Name</label>

    <div
      *ngIf="
        contactForm.dirty &&
        contactForm.touched &&
        contactForm.get('name')?.errors
      "
    >
      Please enter a valid name.
    </div>
  </div>
  <div class="form-floating mb-4">
    <input
      placeholder="Email Address"
      class="form-control"
      id="email"
      type="email"
      formControlName="email"
    />
    <label for="email">Email Address</label>
    <div
      *ngIf="
        contactForm.dirty &&
        contactForm.touched &&
        contactForm.get('email')?.errors
      "
    >
      Please enter a valid email.
    </div>
  </div>
  <div
    class="d-flex flex-row flex-lg-column align-items-center justify-content-between align-items-lg-start mt-3"
  >
    <div class="">
      <button
        class="btn btn-outline-dark action"
        type="submit"
        [ngClass]="{ 'no-click disabled': !contactForm.valid }"
        [disabled]="
          contactForm.dirty && contactForm.touched && !contactForm.valid
        "
      >
        Subscribe
      </button>
    </div>
  </div>
</form>
