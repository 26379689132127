import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { StoryBlokService } from '../service/storyblok.service';
import { ThemeService } from '../service/theme.service';

@Injectable({ providedIn: 'root' })
export class StoryblokPersonaliserParentResolver implements Resolve<any> {
  constructor(
    private storyBlokService: StoryBlokService,
    private router: Router,
    private themeService: ThemeService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    return await this.storyBlokService
      .getStory('personaliser', {})
      .then((result) => {
        this.themeService.viewPage(result);
        const title =
          result?.story?.content?.metatags?.title || result.story.name;
        const description = result?.story?.content?.metatags?.description;
        return {
          ...result,
          seo: { title, description },
        };
      })
      .catch((err) => {
        this.router.navigate([{ outlets: { error: ['404'] } }], {
          skipLocationChange: false,
        });
      });
  }
}
