import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { MercuryService } from '../service/mercury.service';
import { ThemeService } from '../service/theme.service';

@Injectable({ providedIn: 'root' })
export class GiftcardResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private mercuryService: MercuryService,
    private themeService: ThemeService
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const preview = route.queryParamMap.get('preview') as string;
    return await this.mercuryService
      .getProductByHandle('gift-card', Boolean(preview))
      .toPromise()
      .then((result: any) => {
        if (result) {
          this.themeService.viewProduct(result);
          return {
            ...result,
            seo: {
              title: result?.metafields?.global?.title_tag || result?.title,
              description:
                result?.metafields?.global?.description_tag ||
                result?.body_html,
            },
          };
        }
      })
      .catch((err) => {
        console.log(err);
        this.router.navigate([{ outlets: { error: ['404'] } }], {
          skipLocationChange: false,
        });
        return EMPTY;
      });
  }
}
