import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { EMPTY, lastValueFrom } from 'rxjs';
import { MercuryService } from '../service/mercury.service';
import { StoryBlokService } from '../service/storyblok.service';
import { ThemeService } from '../service/theme.service';

@Injectable({ providedIn: 'root' })
export class StoryblokPersonaliserProductResolver implements Resolve<any> {
  constructor(
    private storyBlokService: StoryBlokService,
    private router: Router,
    private mercuryService: MercuryService,
    private themeService: ThemeService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const handle = route.paramMap.get('handle') as string;
    const page = await this.storyBlokService
      .getStory('personaliser-product/' + handle, {
        resolve_relations: ['personaliser-entry.config'],
      })
      .then((result) => {
        this.themeService.viewPage(result);
        const title =
          result?.story?.content?.metatags?.title || result.story.name;
        const description = result?.story?.content?.metatags?.description;
        return {
          ...result,
          seo: { title, description },
        };
      })
      .catch((err) => {
        console.log(err);
        this.router.navigate([{ outlets: { error: ['404'] } }], {
          skipLocationChange: false,
        });
        throw 'Not Found';
      });
    return {
      page: page,
      seo: page.seo,
      product: await lastValueFrom(
        this.mercuryService.getProductByHandle(handle)
      ).catch((err) => {
        this.router.navigate([{ outlets: { error: ['404'] } }], {
          skipLocationChange: false,
        });
        return EMPTY;
      }),
      personalisation: await lastValueFrom(
        this.mercuryService.getProductByHandle('personalisation')
      ).catch((err) => {
        this.router.navigate([{ outlets: { error: ['404'] } }], {
          skipLocationChange: false,
        });
        return EMPTY;
      }),
    };
  }
}
