import {
  DatePipe,
  NgClass,
  NgFor,
  NgIf,
  NgOptimizedImage,
} from '@angular/common';
import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { DynamicModule } from 'ng-dynamic-component';
import { StoryblokLinkDirective } from 'src/app/directive/storyblok-link.directive';
import { StoryblokDirective } from 'src/app/directive/storyblok.directive';
import { EmailFormComponent } from './email-form/email-form.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    DatePipe,
    StoryblokDirective,
    RouterModule,
    DynamicModule,
    NgOptimizedImage,
    StoryblokDirective,
    RouterModule,
    StoryblokLinkDirective,
    EmailFormComponent,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  date: Date = new Date();
  @Input() _editable!: string;
  @Input() _uid!: string;
  @Input() component!: string;
  @Input() theme: any;

  components: any;

  constructor(
    private eRef: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {
    import('src/app/storyblok/components').then((cp) => {
      this.components = cp.Components;
    });

    this.router.events.subscribe((value: any) => {
      if (value instanceof NavigationEnd) {
        const currentUrl = value.url.split('?')[0];
        if (currentUrl.includes('personaliser/')) {
          this.renderer.addClass(this.eRef.nativeElement, 'personaliser');
        } else {
          this.renderer.removeClass(this.eRef.nativeElement, 'personaliser');
        }
      }
    });
  }
}
