import { Injectable } from '@angular/core';
import {
  AddToCartItem,
  Cart,
  Collection,
  Product,
  ProductVariant,
} from '../classes/utility';
import { EnvironmentService } from './environment.service';

declare global {
  interface Window {
    fbq: any;
  }
}
@Injectable({
  providedIn: 'root',
})
export class FbpService {
  constructor(private environmentService: EnvironmentService) {}

  viewPage(page: any) {
    if (!this.environmentService.isBrowser()) return;
    let window = this.environmentService.getWindow()! as Window;
    if (window.fbq) {
      window.fbq('track', 'ViewContent');
    }
  }

  viewProduct(product: Product) {}

  viewCollection(collection: Collection) {}

  addToCart(addToCartItems: AddToCartItem[], cart: Cart) {
    if (!this.environmentService.isBrowser()) return;
    let window = this.environmentService.getWindow()! as Window;
    if (window.fbq) {
      window.fbq('track', 'AddToCart');
    }
  }

  viewCart(cart: Cart) {}

  startCheckout(cart: Cart) {}

  addToWishlist(variant: ProductVariant) {
    if (!this.environmentService.isBrowser()) return;
    let window = this.environmentService.getWindow()! as Window;
    if (window.fbq) {
      window.fbq('track', 'AddToWishlist');
    }
  }

  search(query: string) {
    if (!this.environmentService.isBrowser()) return;
    let window = this.environmentService.getWindow()! as Window;
    if (window.fbq) {
      window.fbq('track', 'Search');
    }
  }
}
